<template>
    <div class="view shop">
        <section class="hero is-dark is-bold">
            <div class="bg-filter"></div>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        marier has <strong>{{ numOfSeries }} series</strong> and <strong>{{ numOfProducts }} products</strong>.
                    </h1>
                </div>
            </div>
            <div class="hero-foot">
                <nav class="tabs is-boxed is-fullwidth">
                    <div class="container">
                        <ul>
                            <li v-bind:class="{ 'is-active': $route.name === 'shop-settings' }">
                                <router-link to="/shop">
                                    <span class="icon is-small">
                                        <b-icon icon="shopping"></b-icon>
                                    </span>
                                    <span>
                                        Settings
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': ['Shop-delivery', 'add-Shop-delivery', 'edit-Shop-delivery'].includes($route.name) }">
                                <router-link to="/shop/delivery">
                                    <span class="icon is-small">
                                        <b-icon icon="truck"></b-icon>
                                    </span>
                                    <span>
                                        Delivery
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': $route.name === 'shop-categories' }">
                                <router-link to="/shop/categories">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="tags"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Product Categories
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': ['Shop-series', 'add-product-series', 'edit-product-series'].includes($route.name) }">
                                <router-link to="/shop/series">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="grip-horizontal"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Product Series
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': ['products', 'add-product', 'edit-product'].includes($route.name) }">
                                <router-link to="/shop/products">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="gift"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Products
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': ['coupons', 'add-coupon', 'edit-coupon'].includes($route.name) }">
                                <router-link to="/shop/coupons">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="gift"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Coupons
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': ['Shop-orders', 'order-details'].includes($route.name) }">
                                <router-link to="/shop/orders">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="shopping-basket"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Orders
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "Shop",
    data() {
        return {
            filterRole: "customer"
        }
    },
    computed: {
        mall () {
            return this.$store.state.mall
        },
        shop () {
            return this.$store.state.shop;
        },
        numOfProducts () {
            return this.$store.getters["shop/products"].length;
        },
        numOfSeries () {
            return this.$store.getters["shop/series"].length;
        },
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.hero {
    background-image: url(~@/assets/hero_background_4.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

    & > .hero-body {
        & > .container {
            width: 100%;

            .title {
                font-weight: 400;

                & strong {
                    font-weight: 700;
                }
            }
        }
    }

    & > .hero-foot {
        .tabs {
            ul {
                margin-left: 10px;
                margin-right: 10px;

                li {
                    &.is-active {
                        color: #363636 !important;
                    }
                }
            }
        }
    }
}
</style>
